//window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

//try {
//    window.Popper = window.popper = require('./popper.js').default;
    window.$ = window.jQuery = require('./jquery.js');

  //  require('./bootstrap.bundle.js');
//} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

//window.axios = require('axios');

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


$(document).ready(function(){
    $("#copiaCriteris").on("click", function(e){
        console.log("Copiant criteris");
        $("#itemsActuals").html("");
        $("#itemsActuals").append($("#itemsAnterior").clone());
    });

    console.log("Ready");
    var actius = $("#contingutsActivitat").data("continguts");

    $("#contingutsActivitat").on("change","#modulContingut" , function(e){
        var codi = $(this).val();

        if(codi != ""){
            
            $.get("/moduls/" + codi + "/ufs/", function(data){
                console.log(data);
                $("#ufContingut").html(""). append("<option value=''>----</option>");
                $.each(data, function(index, value){
                    $("#ufContingut").append("<option value='" + value.id + "'>" + value.codi + " " + value.nom + "</option>");
                });
            });
            $("#listUfs").removeClass("d-none");
        } else {
            $("#listUfs").addClass("d-none");
        }
        $("#continguts").html("").addClass("d-none");
        $("#submitContinguts").addClass("d-none");
    });

    $("#contingutsActivitat").on("change","#ufContingut" , function(e){
        var codi = $(this).val(),
            modul = $("#modulContingut").val();

        if(codi != ""){
            
            $.get("/moduls/" + modul + "/ufs/" + codi + "/continguts/ajax", function(data){
                console.log(data);
                $("#continguts").html("");
                var ocults = actius;
                $.each(data, function(index, value){
                    
                    $("#continguts").append("<li class='list-group-item'>"+ value.codi + ". " + value.descripcio + "</li>");
                    $.each(value.fills, function(index, value){
                        var checked = "";
                        
                        if(actius.includes(value.id)){
                            checked = "checked = 'checked'";
                            ocults = ocults.filter(item => item !== value.id);
                        }
                        $("#continguts").append("<li class='list-group-item'><label>" +  "<input class='form-check-input' name='continguts[]' type='checkbox'" + checked + " value='"+ value.id + " ' >"  + value.codi + ". " + value.descripcio + " (" + value.numActivitats + ") </label></li>");
                    });
                    
                });
                $.each(ocults, function(index, value){
                    $("#continguts").append("<input  name='continguts[]' type='hidden' value='"+ value + " ' >");
                });
            });
            $("#continguts").removeClass("d-none");
            $("#submitContinguts").removeClass("d-none");
        } else {
            $("#continguts").html("").addClass("d-none");
            $("#submitContinguts").addClass("d-none");
        }
    });

    $("#contingutsActivitat").on("click","#submitContinguts" , function(e){
        e.preventDefault();
        var $form = $("#contingutsActivitat > form");
        var data = $form.serialize();
        console.log(data);
        console.log($form.attr("action"));

        $.post($form.attr("action"), data, function(data){
            //$("#submitContinguts").addClass("d-none");
            actius = data.continguts;
            console.log(actius);
        });
        
    });

    $("button.mostrarCriteriAnterior").on("click", function(e){
        let codificacio = $(this).data("anterior");
        e.preventDefault();

        $("#anterior_" + codificacio).toggleClass("d-none");


    });

    $("button.clear-btn").on("click", function(e){
        let codificacio = $(this).data("id");
        e.preventDefault();

        $("input." + codificacio).prop("checked", false);


    });

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});
